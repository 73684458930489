import { useState } from "react";
import { useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";

import { selectPermissions } from "features/authentication/authenticationSlice";
import { hasPermissionToken } from "utilities/data";
import { transformRolePermissions } from "utilities/permission";

const PINNED_COLUMNS = {
  left: ["name"],
};

const OrganizationRoles = ({
  securitySettings,
  permissions,
  onCreateRole,
  onUpdateRole,
  onDeleteRole,
}) => {
  const userPermissions = useSelector(selectPermissions);
  const hasUpdatePermission = hasPermissionToken(
    userPermissions,
    OBJECT_TYPE.MANAGEMENT,
    OBJECT_NAME.MANAGE
  );
  const [open, setOpen] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [permissionFilter, setPermissionFilter] = useState("");

  const hasPermission = (role, permissionId) => {
    return role?.permissions?.some((p) => p.id === permissionId);
  };

  const getPermissionDefinitions = (permissions) => [
    {
      headerName: "Name",
      field: "name",
      sortable: false,
      minWidth: 150,
    },
    ...permissions
      .toSorted(
        (a, b) =>
          a.objectType.localeCompare(b.objectType) ||
          a.name.localeCompare(b.name)
      )
      .map((permission) => ({
        renderHeader: (params) => (
          <Box textAlign="center">
            <p>
              <b>{permission.objectType}</b>
            </p>
            <p>{`.${permission.name}`}</p>
          </Box>
        ),
        field: permission.id.toString(),
        renderCell: ({ row, value }) => {
          const checked = hasPermission(row, permission.id);
          return (
            <Checkbox
              checked={checked}
              disabled={!hasUpdatePermission}
              sx={{ color: grey[300] }}
              onClick={() =>
                onUpdateRole({ ...row, [permission.id]: !checked })
              }
            />
          );
        },
        sortable: false,
        headerAlign: "center",
        align: "center",
        minWidth: 150,
      })),
    {
      headerName: "",
      field: "action_delete",
      sortable: false,
      renderCell: ({ row }) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => onDeleteRole(row.id)}
        >
          DELETE
        </Button>
      ),
    },
  ];

  return (
    <Stack gap={2} mb={2}>
      <Stack direction="row" gap={2} alignItems="baseline" mt={1}>
        <Typography variant="subtitle1" fontWeight="500">
          Roles
        </Typography>
        <TextField
          value={roleFilter}
          label="Filter Roles"
          variant="outlined"
          size="small"
          onChange={(e) => setRoleFilter(e.target.value)}
        />
        <TextField
          value={permissionFilter}
          label="Filter Permissions"
          variant="outlined"
          size="small"
          onChange={(e) => setPermissionFilter(e.target.value)}
        />
        <Box flexGrow={1} />
        <Button
          variant="contained"
          onClick={() => {
            setRoleName("");
            setOpen(true);
          }}
        >
          ADD
        </Button>
        <AddRoleDialog
          open={open}
          setOpen={setOpen}
          roleName={roleName}
          setRoleName={setRoleName}
          onSubmit={onCreateRole}
        />
      </Stack>
      <DataGridPro
        columnHeaderHeight={100}
        columns={getPermissionDefinitions(
          permissions.filter(({ objectType, name }) =>
            `${objectType}.${name}`
              .toLowerCase()
              .includes(permissionFilter.toLowerCase())
          )
        )}
        rows={getRoleDefinitions(
          securitySettings.filter(({ name }) =>
            name.toLowerCase().includes(roleFilter.toLowerCase())
          )
        )}
        filterMode="server"
        sortingMode="server"
        rowsLoadingMode="server"
        rowSelection={false}
        disableColumnMenu
        pinnedColumns={PINNED_COLUMNS}
        hideFooter
        sortingOrder={["asc", "desc"]}
        sx={{
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            transformOrigin: "center",
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
            lineHeight: "normal",
          },
          "& .MuiDataGrid-cell": {
            border: "1px solid #f0f0f0",
          },
          "& .MuiDataGrid-columnHeader": {
            display: "block",
            backgroundColor: "#fafafa",
            border: "1px solid #f0f0f0",
          },
        }}
      />
    </Stack>
  );
};

export default OrganizationRoles;

const AddRoleDialog = ({
  open,
  setOpen,
  roleName,
  setRoleName,
  onSubmit,
  disabled,
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Create Role</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Role Name"
          fullWidth
          variant="outlined"
          onChange={(e) => setRoleName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          variant="contained"
          disabled={disabled}
          onClick={() => {
            setOpen(false);
            onSubmit(roleName, []);
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function getRoleDefinitions(roles) {
  return roles.map((role) => transformRolePermissions(role));
}
