import { useState } from "react";
import { useParams } from "react-router-dom";
import { components } from "@tsc/component-library/lib/commonTheme";
import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
} from "@tsc/component-library/lib/components";
import { useDebounce, usePrevious } from "@uidotdev/usehooks";
import { STAKEHOLDERS_SORT_BY } from "enums/stakeholderSortBy";
import { isEmpty, keyBy, merge, uniq } from "lodash";

import { useGetStakeholdersQuery } from "api/stakeholders";
import StakeholderCardItem from "features/stakeholders/StakeholderCardItem/StakeholderCardItem";
import { getIdArray } from "utilities/array";
import { getStakeholderAttribute } from "utilities/stakeholder";

const StakeholderIdDropdown = ({
  autoFocus,
  value = [],
  onChange,
  disabled,
  sx,
  label,
  disableCloseOnSelect,
  getOptionDisabled,
  stakeholderType,
  multiple,
  placeholder,
  filterOptions = (options) => options,
}) => {
  const { organizationId, projectId } = useParams();
  const [inputValue, setInputValue] = useState("");
  const debouncedSearchTerm = useDebounce(inputValue, 300);

  // when stakeholders are selected
  const { data: { data: stakeholderValues = [] } = {} } =
    useGetStakeholdersQuery(
      {
        organizationId,
        projectId,
        params: {
          ids: multiple ? uniq(value).join(",") : value,
          pageSize: multiple ? value.length : 1,
        },
      },
      { skip: multiple ? isEmpty(value) : !value }
    );

  // when user types something
  const { data: stakeholderOptionsData, isFetching } = useGetStakeholdersQuery(
    {
      organizationId,
      projectId,
      params: {
        name: debouncedSearchTerm ?? "",
        type: stakeholderType ?? "",
        sortBy: STAKEHOLDERS_SORT_BY.SEARCH_RELEVANCE,
      },
    },
    {
      skip:
        debouncedSearchTerm === false ||
        debouncedSearchTerm?.trim()?.length < 2,
    }
  );
  const previousStakeholderOptions = usePrevious(stakeholderOptionsData?.data);

  const stakeholderValuesMap = keyBy(stakeholderValues, "id");
  const stakeholderOptionsMap = keyBy(stakeholderOptionsData?.data, "id");
  const previousStakeholderOptionsMap = keyBy(previousStakeholderOptions, "id");
  const stakeholderMap = merge(
    stakeholderValuesMap,
    stakeholderOptionsMap,
    previousStakeholderOptionsMap
  );

  const handleInputChange = (event, value, reason) => {
    // To prevent the options from being reset when the user selects an option.
    if (event?.type !== "blur" && reason === "reset") {
      setInputValue(false);
      return;
    }
    setInputValue(value);
  };

  return (
    <Autocomplete
      className="notranslate"
      sx={sx}
      multiple={multiple}
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      size="small"
      fullWidth
      options={getIdArray(stakeholderOptionsData?.data ?? [])}
      filterOptions={filterOptions}
      getOptionKey={(id) => id}
      getOptionLabel={(id) =>
        getStakeholderAttribute(stakeholderMap[id], "name")
      }
      getOptionDisabled={getOptionDisabled}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      onInputChange={handleInputChange}
      onClose={() => setInputValue("")}
      loading={isFetching}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...components.MuiTextField.defaultProps.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={label}
          placeholder={value?.length > 0 ? "" : placeholder}
        />
      )}
      renderOption={(props, id) => (
        <Box px={2} py={0.5} {...props}>
          <StakeholderCardItem
            stakeholder={stakeholderMap[id]}
            avatarSize="medium"
          />
        </Box>
      )}
    />
  );
};

export default StakeholderIdDropdown;
