import eu_logo from "assets/images/eu_logo.jpeg";
import genie_logo from "assets/images/genie.png";
import linkedin_logo from "assets/images/linkedin_logo.png";
import littlesis_logo from "assets/images/littlesis_logo.png";
import tsc_logo from "assets/images/tsc_source_logo.png";
import wikidata_logo from "assets/images/wikidata_logo.png";

// It's planned that "Manual" will come to include Atium sources when Atium is officially phased out.
export const STAKEHOLDER_SOURCE = {
  GENFIVE_OVERRIDE: "genfive_override",
  GENFIVE_AUTOMATION: "genfive_automation",
  MANUAL: "manual",
  ATIUM: "atium",
  LITTLESIS: "littlesis",
  WIKIDATA: "wikidata",
  LINKED_IN: "linkedin",
  EU_TRANSPARENCY_REGISTER: "eu_transparency_register",
  OTHERS: "others",
};

export const ATIUM_SOURCE_IDENTIFIER = "TSC";

export const STAKEHOLDER_SOURCE_FILTER = {
  ...STAKEHOLDER_SOURCE,
  ALL: "",
  NO_SOURCE: "noSource",
};

export const STAKEHOLDER_SOURCE_LABEL = {
  [STAKEHOLDER_SOURCE_FILTER.ALL]: "All",
  [STAKEHOLDER_SOURCE_FILTER.ATIUM]: ATIUM_SOURCE_IDENTIFIER,
  [STAKEHOLDER_SOURCE_FILTER.LINKED_IN]: "LinkedIn",
  [STAKEHOLDER_SOURCE_FILTER.LITTLESIS]: "Littlesis",
  [STAKEHOLDER_SOURCE_FILTER.WIKIDATA]: "Wikidata",
  [STAKEHOLDER_SOURCE_FILTER.EU_TRANSPARENCY_REGISTER]:
    "EU Transparency Register",
  [STAKEHOLDER_SOURCE_FILTER.NO_SOURCE]: "No source",
};

export const DISPLAYED_STAKEHOLDER_SOURCE = [
  STAKEHOLDER_SOURCE.GENFIVE_OVERRIDE,
  STAKEHOLDER_SOURCE.MANUAL,
  STAKEHOLDER_SOURCE.LITTLESIS,
  STAKEHOLDER_SOURCE.WIKIDATA,
  STAKEHOLDER_SOURCE.ATIUM,
  STAKEHOLDER_SOURCE.LINKED_IN,
  STAKEHOLDER_SOURCE.EU_TRANSPARENCY_REGISTER,
];

export const STAKEHOLDER_SOURCE_PRIORITY = {
  [STAKEHOLDER_SOURCE.GENFIVE_OVERRIDE]: 10,
  [STAKEHOLDER_SOURCE.MANUAL]: 20,
  [STAKEHOLDER_SOURCE.ATIUM]: 30,
  [STAKEHOLDER_SOURCE.LINKED_IN]: 40,
  [STAKEHOLDER_SOURCE.EU_TRANSPARENCY_REGISTER]: 50,
  [STAKEHOLDER_SOURCE.WIKIDATA]: 60,
  [STAKEHOLDER_SOURCE.LITTLESIS]: 70,
  [STAKEHOLDER_SOURCE.GENFIVE_AUTOMATION]: 80,
  [STAKEHOLDER_SOURCE.OTHERS]: 90,
};

export const STAKEHOLDER_SOURCE_LOGO = {
  [STAKEHOLDER_SOURCE.GENFIVE_OVERRIDE]: genie_logo,
  [STAKEHOLDER_SOURCE.WIKIDATA]: wikidata_logo,
  [STAKEHOLDER_SOURCE.LITTLESIS]: littlesis_logo,
  [STAKEHOLDER_SOURCE.ATIUM]: tsc_logo,
  [STAKEHOLDER_SOURCE.LINKED_IN]: linkedin_logo,
  [STAKEHOLDER_SOURCE.EU_TRANSPARENCY_REGISTER]: eu_logo,
};
